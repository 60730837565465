@import "../../../sass/variables";

.forgotten-password {
  border: $border-very-light-gray;
  border-radius: $border-radius-light;
  box-shadow: $shaddow-bottom-light;
  padding: 10vh 5vw 10vh 5vw;
  margin-top: 20vh;
  margin-left: auto;
  margin-right: auto;
  width: 50vw;
  form {

  }
}