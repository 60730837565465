@import '../../../sass/variables';

.centralWrapper {
  z-index: 0;
  position: relative;
  @media #{$is-xl} {
    margin-top: $header_height;
    margin-left: $navbar_width;
    padding-left: $huge-padding;
    padding-right: $huge-padding;
    padding-top: calc(#{$huge-padding}/3);
  }

  @media #{$is-md} {
    padding: $u-3 $u-3;
    margin-top: $header_height;
    margin-left: $navbar_width_ltd;
  }

  @media #{$is-sm} {
    padding: $u-3 0;
    margin-top: $header_height;
    margin-left: 0;
  }
  // margin-bottom: calc(#{$footer_height} + 300px);
}