$header-height: 75px;
$navbar_width: 220px;
$navbar_width_ltd: 80px;
$footer_height: 80px;

// units
$u-05: 0.4rem;
$u-1: 0.5rem;
$u-2: 0.75rem;
$u-3: 1rem;
$u-4: 1.5rem;
$u-5: 2rem;
$u-6: 3rem;
$u-7: 4rem;

$huge-padding: 8rem;

// box-shaddows
$shaddow-bottom-light: 0 1px 5px 0 rgba(25, 25, 34, 0.08);
$shaddow-bottom-1: 0 1px 12px 0 rgba(25,25,34,.12);
$shaddow-bottom-2: 0 1px 2px 0 rgba(25,25,34,.12);
$shaddow-bottom-2-heavy: 0 4px 6px 0 rgba(25,25,34,.30);

$shaddow-top-1: 0 -1px 12px 0 rgba(25,25,34,.12);

// colors
$pull_blue: #5CC5EF;
$light_blue_gray: #efeff2;
$main_color: rgb(52,77,144);
$invalid: rgba(255, 0, 0, 0.596);
$very-light-gray: rgb(233, 233, 233);
$medium-gray: rgb(150, 150, 150);
$ultra-light-gray: rgb(249, 249, 249);

// borders
$border-light-gray: 1px solid lightgrey;
$border-very-light-gray: 1px solid rgb(233, 233, 233);
$border-medium-gray: 1px solid $medium-gray;

// border-radius
$border-radius-light: 4px;
$border-radius-fat: 40px;

$color-free: rgb(63, 104, 28);
$color-jam: rgb(254, 122, 71);
$color-training: rgb(55, 94, 151);
$color-concert: rgb(181, 29, 10);
$color-other: rgb(158, 158, 158);
$color-course: rgb(76, 63, 84);
$color-record: rgb(128, 90, 59);

$xs: 768px;
$sm: 992px;
$md: 1200px;

$is-sm : "screen and (max-width : 575px)";
$is-not-sm : "screen and (min-width : 576px)";
$is-md: "screen and (max-width : 1199px) and (min-width : 576px)";
$is-not-xl: "screen and (max-width : 1199px)";
$is-xl: "screen and (min-width : 1200px)";