@import '../../../sass/_variables.scss';

.connexion {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: $u-2;
  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: $u-5;
    width: 500px;
    @media #{$is-sm} {
      width: 100%;
    }
    input {
      border-radius: 15px;
      width: 100%;
      font-size: 1.3rem;
      margin-bottom: 1rem;
      font-weight: 300;
    }
    .buttonWrapper {
      height: 100%;
      width: 100%;
      margin-bottom: 1rem;
      button {
        border-radius: 15px;
        width: 100%;
        height: 100%;
        font-size: 1.2rem;
        padding: .6rem;
      }
    }
  }
  .subWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    display: -webkit-flex;
    -webkit-flex-direction: column;
    -webkit-justify-content: center;
    -webkit-align-items: center;
    span, a {
      font-size: 0.8rem;
      margin-bottom: .7rem;
    }
    span {
      transition: opacity .3s ease-in-out;
      opacity: 0;
      &.active {
        opacity: 1;
      }
      font-weight: 600;
    }
  }
}