@import '../../../../sass/variables';

.profile-wrapper {
  button {
    padding: 0 !important;
    color: rgb(180, 180, 200);
    &:hover {
      color: rgb(160, 160, 180);
    }
  }
  .profile-header {
    display: flex;
    .image-wrapper {
      border-radius: 50%;
      border: $border-very-light-gray;
      overflow: hidden;
      img {
        height: auto;
        width: 100%;
      }
    }
    @media screen and (min-width: $md) {
      .image-wrapper {
        width: 10rem;
        height: 10rem;
      }

    }
    @media screen and (max-width: $md) {
      .image-wrapper {
        width: 3rem;
        height: 3rem;
      }
    }
    .infos {
      margin-left: $u-3;
      display: flex;
      justify-content: center;
      flex-direction: column;

    }
  }

  .groups {
    .header-group {
      display: flex;
      flex-direction: row;
    }

    .header-group > button, h2 {
      display: flex;
      justify-content: center;
      align-items: center;

    }
    .header-group > h2 {
      margin: 0 $u-3 0 0;
    }
    .header-group > button {
      font-size: 2.5rem;
    }
    .group-list {
      li {
        h3 {
          font-size: 1.5rem;
        }
        margin-bottom: $u-2;
        border: $border-very-light-gray;
        border-radius: $border-radius-light;
        width: 100%;
        padding: $u-3;
      }
    }
  }
}

.profile-wrapper > div {
  padding: $u-5;
}

.form-footer {
  justify-content: right;
  text-align: right;
  .abord {
    margin-right: $u-3;
  }
}

.modal-new-group {
  width: 650px;
  input {
    background: white;
    &:focus {
      border: $border-light-gray;
    }
  }
  .new-member-wrapper {
    display: flex;
    border-top: $border-very-light-gray;
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      border: 1px solid transparent;
      transition: padding-left 0.1s ease-in-out;
      &:hover {
        background: $ultra-light-gray;
        border: $border-very-light-gray;
      }
    }
    .search-wrapper {
      padding-top: $u-2;
      width: 60%;
      padding-right: $u-2;
      .input-wrapper {
        padding-bottom: $u-2;
        border-bottom: $border-very-light-gray;
        input {
          width: 100%;
        }
      }
      li {
        font-size: 0.9rem;
        font-weight: 600;
        padding: $u-1 $u-1 $u-1 0;
        &:hover {
          padding-left: $u-1;
        }
      }
    }
    .member-wrapper {
      background: $ultra-light-gray;
      flex: 1;
      height: 40vh;
      .header-member {
        padding-top: $u-2;
        text-align: center;
        span {
          vertical-align: top;
        }
      }
      .member-element {
        padding: $u-1;
        font-size: 0.8rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid transparent;
        &:hover {
          background: $light_blue_gray;
        }
      }
    }
  }
}