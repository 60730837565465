@import '../../../../sass/variables';

.user-params {
  .user-param-generic-section {
    h2.title {
      color: gray;
      border-bottom: $border-light-gray;
    }
    .section-content {
      padding-top: $u-4;
    }
    .user-param-generic-action {
      display: flex;
      flex-direction: row;
      margin-bottom: $u-7;
      @media screen and (max-width: $md) {
        flex-direction: column;
      }

      h3 {
        color: gray;
        font-size: 1rem;
        font-weight: 600;
        width: 200px;
      }
      .content {
        flex: 1;
        @media screen and (max-width: $md) {
          padding-left: 0;
          form {
            width: 100% !important;
          }
        }
        padding-left: $u-5;
        form {
          width: 500px;
        }

      }
    }
  }
  .input-search-member {
    margin-bottom: $u-3;
    input {
      width: 100%;
    }
  }
  .headerMembers, .headerBookings {
    padding: 0 $u-3 $u-3 $u-3;
    text-align: center;
    font-weight: 600;
  }

  .member, .bookings, .regular-booking {
    .custom-dropdown__select {
      padding: 0.3rem;
      height: 100%;
    }
    input {
      width: 100%;
    }
    &:first-child {
      border-top: $border-very-light-gray;
    }
    justify-content: center;
    text-align: center;
    padding: $u-3;
    border-bottom: $border-very-light-gray;

  }
  .dropdown {
    .no-found {
      display: flex;
      padding: .5rem;
      flex-direction: row;
      justify-content: center;
      span {
        height: 100%;
        text-align: center;
      }
    }
    span {
      height: 0;
    }
  }
  .dropdown-menu {
    width: 100%;
    z-index: 2000;
    padding-top: 0;
    margin-top: 0;
  }
  label {
    color: gray;
    font-weight: 600;
  }
  .selected-booker {
    display: flex;
    .booker {
      border: $border-very-light-gray;
      padding: $u-2;
      font-weight: 600;
      border-radius: $border-radius-light;
    }
  }

}