@import '../../../sass/variables';

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: $navbar_width;
  &.mobile {
    width: 100%;
    z-index: 1001 !important;
    margin-top: $header-height;
    #exit-menu-button {
      position: absolute;
      z-index:100;
      width: 50px;
      right: $u-05;
      top: $u-05;
    }
    font-size: 1.1rem;
    transform: translate(-100%);
    transition: all .15s ease-in-out;
    &.active {
      transform: translate(0);
    }
  }
  &.limited {
    width: $navbar_width_ltd;
    img {
      margin-right: 0;
    }
  }
  background: $main_color;
  height: 100%;
  display: block;
  padding: 0;
  .logo_wrapper {
    height: 50px;
    display: flex;
    padding: $u-2;
    a {
      margin: auto;
    }
    img {
      width: auto;
      height: 40px;
    }
  }
}