.spinner-1 {
  display: inline-block;
  width: 2rem;
  height: 2rem;
}
.spinner-1:after {
  content: " ";
  display: block;
  width: 1.2rem;
  height: 1.2rem;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid white;
  border-color: white transparent white transparent;
  animation: spinner-1 1.2s linear infinite;
}
@keyframes spinner-1 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner-2 {
  position: fixed;
  top: 40vh;
  left: calc(50vw - 50px);
  display: inline-block;
  width: 100px;
  height: 100px;
}
.spinner-2:after {
  content: " ";
  display: block;
  width: 100px;
  height: 100px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid white;
  border-color: gray transparent gray transparent;
  animation: spinner-1 1.2s linear infinite;
}