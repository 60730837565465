@import '../../../sass/variables';

.header {
  background: white;
  z-index: 1000;
  position: fixed;
  top: 0;
  right: 0;
  height: $header_height;
  box-shadow: $shaddow-bottom-1;
  box-sizing: border-box;
  width: calc(100% - #{$navbar_width});
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $u-2 $u-5;
  @media #{$is-sm} {
    padding: $u-2 $u-2;
  }
  transition: opacity .25s ease-in-out;
  &.mobileMode {
    width: 100%;
    .link-header-welcome {
      width: 45px;
      img {
        width: 100%;
      }
    }
    .access-div {
      #menu-button {
        margin-right: $u-05;
        width: 50px;
        height: 50px;
        opacity: .75;
      }
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
    }
  }
  &.limited {
    width: calc(100% - #{$navbar_width_ltd})
  }
  &.invisible {
    opacity: 0;
  }
  input {
    height: 100%;
  }
}