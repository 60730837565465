@import '../../../../sass/variables';

$booking-header-height: 90px;
$booking-slot-height: 80px;

.booking-section {
  display: flex;
  flex-direction: column;
  .weekSelector {
    @media #{$is-xl} {
      margin-bottom: $u-4
    }
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    color: gray;
    font-size: 1.5rem;
    @media #{$is-sm} {
      font-size: 1.2rem;
    }
    .arrow-wrapper {
      @media #{$is-not-xl} {
        button {
          width: 3rem !important;
          height: 3rem !important;
        }
      }
      display: flex;
      flex-direction: column;
      justify-content: center;
      button {
        @media #{$is-sm} {
          height: 3rem !important;
          width: 3rem !important;

        }
        width: 4rem;
        height: 4rem;
        opacity: 0.2;
        transition: all 0.1s ease-in-out;
        box-shadow: $shaddow-bottom-light;
        &:hover {
          opacity: 0.3;
        }
        &:active {
          transform: scale(0.8, 0.8);
          opacity: 0.4;
        }
        img {
          top: -0.15rem;
          height: 99%;
          width: 99%;
        }
      }
    }
  }
}

.booking-section-wrapper {
  display: flex;
  flex-direction: row;
  .header-side-col {
    height: $booking-header-height;
  }
  .side-col {
    width: 50px;
    @media #{$is-sm} {
      width: auto;
    }
  }
  .bookings-header {
    height: $booking-header-height;
  }
  .bookings-wrapper {
    flex: 1;
    border: $border-very-light-gray;
    display: flex;
    flex-direction: row;
    .day-col {
      &.today {
        background: $ultra-light-gray;
        .bookings-header {
          color: #4285f4;
        }
      }
      &:last-child {
        background: #ffeef7;
        opacity: 0.5
      }
      flex: 1;
      flex-basis: 0;
      border-right: $border-very-light-gray;
      overflow: hidden;
      &:last-child {
        border-right: none;
      }
      .bookings-header {
        padding: $u-1;
        font-size: 0.9rem;
        // color: gray;
        display: flex;
        flex-direction: column;
        .dateNumber {
          // color: $medium-gray;
          flex: 1;
          font-size: 3rem;
          @media #{$is-sm} {
            font-size: 1.3rem
          }
        }
      }
      .booking-slot {
        padding: $u-05;
        height: $booking-slot-height;
        color: white;
        border-top: $border-very-light-gray;
        .booking {
          height: 100%;
          padding: $u-05;
          font-size: 0.8rem;
          @media #{$is-sm} {
            font-size: .5rem;
            word-wrap: break-word;
          }
          border-radius: $border-radius-light;
          box-shadow: $shaddow-bottom-2;
          &.allowedModif {
            cursor: pointer;
            transition: all 0.15s ease-in-out;
            &:hover {
              box-shadow: $shaddow-bottom-2-heavy;
            }
          }
          &.jam {
            background: $color-jam;
          }
          &.training {
            background: $color-training;
          }
          &.concert {
            background: $color-concert;
          }
          &.other {
            background: $color-other;
          }
          &.course {
            background: $color-course;
          }
          &.record {
            background: $color-record;
          }
          .booking-type {
            font-weight: 600;
          }
        }
      }
    }
  }


  .side-col {
    .header-side-col {
      height: $booking-header-height;
    }
    .booking-slot-hours {
      display: flex;
      font-size: 0.7rem;
      // color: gray;
      height: $booking-slot-height;
      justify-content: space-between;
      flex-direction: column;
      padding-right: $u-05;
      .end, .begin {
        margin-left: auto;
      }
      .end {
        margin-bottom: -0.6rem;
      }
    }
  }

}