@import "../../../../../sass/variables";

$item-width: 300px;

.carousel {
  width: 100%;
  display: block;
  position: relative;
  overflow: hidden;
  padding: $u-2;
  .carousel-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    .buttonWrapper {
      display: flex;
      justify-content: space-between;
      width: 6rem;
      button {
        height: 2rem;
        width: 2rem;
        border-radius: 50%;
        &:hover {
          svg path {
            stroke: gray;
          }
        }
        &.left {
          left: 0;
          img {
            margin-left: -0.15rem;
          }
        }
        &.right {
          right: 0;
          img {
            margin-right: 0.15rem;
          }
        }
        img {
          position: relative;
          top: -0.15rem;
          height: 99%;
          width: 99%;
        }
      }
    }
  }
  .carousel-inner-container {
    overflow: hidden;
    min-width: $item-width;
  }
  .carousel-inner {
    height: 150px;
    display: block;
    white-space: nowrap;
    overflow: visible;
    transition: transform 0.3s ease-in-out;
    margin: $u-1 0 $u-1 0;

    @media #{$is-sm} {
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .carousel-slide {
      border: $border-very-light-gray;
      box-shadow: $shaddow-bottom-light;
      border-radius: $border-radius-light;
      margin: 0 $u-2 0 $u-2;
      height: 100%;
      width: $item-width;
      transition: transform .6s linear, -webkit-transform .6s ease;
      display: inline-block;

      @media #{$is-sm} {
        margin: 0 0 $u-4 0;
      }

      .slide-content {
        height: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        .slide-header {
          flex: 1;
          display: flex;
          justify-content: space-between;
          font-size: 0.8rem;
          flex-direction: column;
          padding: $u-1;
          margin-bottom: $u-1;
          color: white;
          transition: background-color 0.3s ease-in-out;
          &.free {
            background: $color-free;
          }
          &.jam {
            background: $color-jam;
          }
          &.training {
            background: $color-training;
          }
          &.concert {
            background: $color-concert;
          }
          &.other {
            background: $color-other;
          }
          &.course {
            background: $color-course;
          }
          &.record {
            background: $color-record;
          }
          .top-row {
            height: 2.2rem;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            button {
              height: 2.2rem;
              width: 2.2rem;
              padding: 0.25rem;
              border: 1px solid white;
              background: none;
              border-radius: 50%;
              &:hover, &:focus {
                img {
                  transform: scale(1.2, 1.2);
                }
              }
              img {
                transition: transform 0.1s ease-in-out;
                height: 1rem;
              }
            }
          }
          .bottom-row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            text-align: center;
          }
          .hoursWrapper {
            font-size: 1.3rem;
          }
          .book-type-wrapper {
            flex-direction: column;
            .booking-type {
              font-size: 1.6rem;
              font-weight: 600;
            }
          }
        }
        .booking-content {
          flex: 1;
          text-align: center;
          vertical-align: middle;
          span {
            font-size: 1.3rem;
          }
        }
      }
      &:first-child {
        @media #{$is-not-sm} {
          margin: 0 $u-2 0 0;
        }
      }
      &:last-child {
        @media #{$is-not-sm} {
          margin: 0 0 0 $u-2;
        }
      }
      &.next {
        animation: 4s slidy infinite;
      }
    }
  }
}

.slide-modal-body {
  padding: $u-5 $u-4 $u-5 $u-4 !important;
  .label-container {
    display: flex;
    align-items: center;
  }
  .select-container {
    display: flex;
    justify-content: left;
    align-items: center;
  }
  label {
    margin: 0;
    align-content:center;
    text-align: center;
    color: gray;
    font-size: 1.5rem;
  }
  ul {
    padding-left: 0;
    list-style: none;
    li {
      font-size: 2rem;
    }
  }
}
