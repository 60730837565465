@import '../../../../sass/variables';

.MainLoader {
    .wrapper {
        position: fixed;
        width: 10vw;
        height: 10vw;
        @media #{$is-sm} {
            width: 200px;
            height: 200px;
            top: calc(50vh - calc(200px)/2);
            left: calc(50vw - calc(200px)/2);
        }
        top: calc(50vh - calc(10vw)/2);
        left: calc(50vw - calc(10vw)/2);
        div.active {
            animation:rotate ease-in-out;
            animation-duration: 2s;
        }
        img {
            height: 100%;
            width: 100%;
        }
    }
}

@keyframes rotate {
    from { transform: rotate(0deg);  }
    to {    transform: rotate(360deg);  }
  }    