@import 'variables';

body {
  font-family: Helvetica Neue,Helvetica,Arial,sans-serif !important;
}

a {
  &.no-style {
    text-decoration: none;
    outline: 0;
    border: none;
    outline-style: none;
    &:focus, &:visited, &:hover, &:link, &:active {
      text-decoration: none;
      outline: 0;
      border: none;
      outline-style: none;
    }
  }
}

.mb-3 {
  margin-bottom: $u-3;
}

form {
  .form-footer {
    border-top: $border-very-light-gray;
    margin-top: $u-2;
    padding-top: $u-2;
  }
  .submit-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: right;
    button {
      margin-left: $u-3;
    }
  }
}

.form-group {
  display: flex;
  flex-direction: column;
  input {
    width: 100%;
  }
  label {
    width: 100%;
  }
}

input {
  border-radius: $border-radius-light;
  border: 1px solid lightgrey;
  box-sizing: border-box;
  padding: $u-1;
  background-color: $light_blue_gray;
  transition-duration: .15s;
  transition-property: background-color, border-color, box-shadow, -webkit-box-shadow;
  margin-bottom: 1rem !important;
  &:focus {
    border-color: rgb(2, 128, 235);
    background-color: #fff;
    box-shadow: 0 1px 2px 0 rgba(25, 25, 34, .12);
  }
  &.valid {
    border-color: green;
  }
  &.invalid {
    border-color: $invalid;
  }
}

button {
  font-weight: 600;
  border-radius: $border-radius-light;
  padding: .20rem .5rem;
  color: white;
  background: rgb(82, 108, 214);
  border: 2px solid $main_color;
  transition-duration: .15s;
  transition-property: background-color, border-color, opacity;
  text-align: center;
  &.disabled {
    pointer-events: none;
    opacity: 0.7;
    background: rgb(82, 108, 214);
    cursor: default;
    border: 2px solid transparent;
    &:hover, &:focus, &:active {
      background: rgb(82, 108, 214);
      opacity: 0.7;
      border: 2px solid transparent;
    }
  }
  &.validate {
    background: rgb(46,204,64);
    border: 2px solid rgb(43, 168, 52);
    &:hover {
      background: rgb(43, 168, 52);
    }
    &:focus {
      background: rgb(28, 132, 37);
      border: 2px solid rgb(43, 168, 52);
      outline: 0;
    }
  }
  &.gray {
    background: rgb(180,180,180);
    border: 2px solid rgb(160, 160, 160);
    &:hover {
      background: rgb(160, 160, 160);
    }
    &:focus {
      background: rgb(140, 140, 140);
      border: 2px solid rgb(160, 160, 160);
      outline: 0;
    }
  }
  &.abord {
    background: rgb(204,46,64);
    border: 2px solid rgb(173, 42, 54);
    &:hover {
      background: rgb(173, 42, 54);
    }
    &:focus {
      background: rgb(122, 31, 37);
      border: 2px solid rgb(173, 42, 54);
      outline: 0;
    }
  }
  &.fat {
    padding: .6rem 1.5rem;
  }
  &:hover {
    background: $main_color;
  }
  &:focus {
    background: rgb(39, 63, 116);
    border-color: rgb(121, 150, 230);
    outline: 0;
  }
  &.flat {
    cursor: pointer;
    color: gray;
    background: rgb(240, 240, 241);
    border: none;
    border-radius: $border-radius-fat;
    &:hover {
      background: rgb(215, 215, 216);
    }
    &:focus {
      outline: 0;
    }
  }
  &.no-style {
    cursor: pointer;;
    background: transparent;
    // padding: 0;
    color: gray;
    border-radius: $border-radius-light;
    border: 2px solid transparent;
    &.sm {
      padding: 0 $u-05;
    }
    &:focus {
      outline: 0;
    }
    &.backgrounded-hover {
      &:hover {
        background: rgba(0, 0, 0, 0.07);;
        border-radius: $border-radius-light;
        &.red {
          background: rgba(255, 0, 0, 0.1);
        }
      }
      &:active {
        background: rgba(0, 0, 0, 0.15);;
      }
    }
  }
  img {
    position: relative;
    width: 100%;
    height: 1.5rem;
    img {
      position: relative;
      width: 100%;
    }
  }

}

span {
  &.error {
    color: $invalid;
    font-size: 0.8rem;
    font-weight: 600;
  }
  &.info {
    color: gray;
    font-size: 0.8rem;
    font-weight: 600;
    &.valid {
      color: green;
    }
  }
}

.TransFade-enter {
  opacity: 0;
}

.TransFade-leave {
  opacity: 1;
  transform: translateY(0);
}

.TransFade-enter .TransFade-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 1s ease;
  transition-property: transform, opacity;
  transition-duration: 150ms;
  transition-timing-function: cubic-bezier(0.175, 0.665, 0.320, 1), linear;
}

.TransFade-leave.TransFade-leave-active {
  opacity: 0;
  transition-property: transform, opacity;
  transition-duration: 150ms;
  transition-timing-function: cubic-bezier(0.175, 0.665, 0.320, 1), linear;
}

.navTrans-enter {
  transform: translateX(-10px);
}

.navTrans-leave {
  transform: translateX(0);
}

.navTrans-enter .navTrans-enter-active {
  transform: translateX(-10px);
  transition: transform 1s ease;
  transition-property: transform;
  transition-duration: 150ms;
  transition-timing-function: cubic-bezier(0.175, 0.665, 0.320, 1), linear;
}

.navTrans-leave.navTrans-leave-active {
  transform: translateX(0);
  transition-property: transform;
  transition-duration: 150ms;
  transition-timing-function: cubic-bezier(0.175, 0.665, 0.320, 1), linear;
}

.lightTransX-enter {
  opacity: 0;
  transform: translateX(calc(-#{$u-1}));
}

.lightTransX-leave {
  opacity: 1;
  transform: translateX(0);
}

.lightTransX-enter.lightTransX-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 1s ease;
  transition-property: transform, opacity;
  transition-duration: 200ms;
  transition-timing-function: cubic-bezier(0.175, 0.665, 0.320, 1), linear;
}

.lightTransX-leave.lightTransX-leave-active {
  transform: translateX(calc(-#{$u-1}));
  opacity: 0;
  transition-property: transform, opacity;
  transition-duration: 200ms;
  transition-timing-function: cubic-bezier(0.175, 0.665, 0.320, 1), linear;
}

.fade-appear {
  opacity: 0.01;
}

.fade-appear.fade-appear-active {
  opacity: 1;
  transition: opacity .20s ease-in;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}