@import '../../../../sass/variables';

.profileBar {

  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  .profileButton {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    box-shadow: $shaddow-bottom-2;
    transition: box-shadow 0.15s ease-in-out;
    overflow: hidden;
    padding: 0;
    margin: 0;
    img {
      width: 100% !important;
      height: auto !important;
    }
    &:hover {
      box-shadow: $shaddow-bottom-2-heavy;
    }
  }
}

.popover {
  width: 320px;
  &.profileBar {
    border: $border-very-light-gray;
    box-shadow: $shaddow-bottom-1;
  }
  .popover-inner {
    width: 100%;
  }
  .popover-body {
    padding: 0;
  }
  ul {
    padding: $u-1;
    list-style: none;
    margin: 0;
    &.profileAccess {
      padding: $u-2;
      font-size: 1.3rem;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
    &.navItem {
      li {
        button {
          font-weight: 500;
          padding: $u-05;
          text-align: left;
          width: 100%;
        }
      }
    }
  }
}