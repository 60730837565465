@import "../../../../sass/variables";

.custom-dropdown--large {
  border: $border-very-light-gray;
  border-radius: $border-radius-light;
  overflow: hidden;
  font-size: 1.5em;
}

.custom-dropdown--small {
  font-size: .7em;
}

.custom-dropdown__select{
  width: 100%;
  font-size: inherit; /* inherit size from .custom-dropdown */
  padding: .5em; /* add some space*/
  margin: 0; /* remove default margins */
}

.custom-dropdown__select--white {
  background-color: #fff;
  color: #444;
}

@supports (pointer-events: none) and
      ((-webkit-appearance: none) or
      (-moz-appearance: none) or
      (appearance: none)) {

  .custom-dropdown {
    position: relative;
    display: inline-block;
    vertical-align: middle;
  }

  .custom-dropdown__select {
    padding-right: 2.5em; /* accommodate with the pseudo elements for the dropdown arrow */
    border: 0;
    border-radius: 3px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .custom-dropdown::before,
  .custom-dropdown::after {
    content: "";
    position: absolute;
    pointer-events: none;
  }

  .custom-dropdown::after { /*  Custom dropdown arrow */
    content: "\25BC";
    height: 1em;
    font-size: .625em;
    line-height: 1;
    right: 1.2em;
    top: 50%; margin-top: -.5em;
  }

  .custom-dropdown::before { /*  Custom dropdown arrow cover */
    width: 2em;
    right: 0; top: 0; bottom: 0;
    border-radius: 0 3px 3px 0;
  }

  .custom-dropdown__select[disabled] {
    color: rgba(0,0,0,.3);
  }

  .custom-dropdown.custom-dropdown--disabled::after {
    color: rgba(0,0,0,.1);
  }

  /* White dropdown style */
  .custom-dropdown--white::before {
    top: .5em; bottom: .5em;
    background-color: #fff;
    border-left: 1px solid rgba(0,0,0,.1);
  }

  .custom-dropdown--white::after {
    color: rgba(0,0,0,.9);
  }

  /* FF only temp fix */
  @-moz-document url-prefix() {
    .custom-dropdown__select              { padding-right: .9em }
    .custom-dropdown--large .custom-dropdown__select { padding-right: 1.3em }
    .custom-dropdown--small .custom-dropdown__select { padding-right: .5em }
  }
}