@import '../../../../sass/variables';

.bands {
  .band-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: $u-3;
  }
}

.band-image {
  @media screen and (max-width: $sm) {
    width: 50px;
    height: 50px;
  }
  @media screen and (max-width: $md) {
    width: 70px;
    height: 70px;
  }
  @media screen and (min-width: $md) {
    width: 110px;
    height: 110px;
  }
  border-radius: 50%;
  box-shadow: $shaddow-bottom-2;
  transition: box-shadow 0.15s ease-in-out;
  overflow: hidden;
  &:hover {
    box-shadow: $shaddow-bottom-2-heavy;
  }
  img {
    height: 100%;
    max-width: 100%;
  }
}

.band {
  h2 {
    .band-image {
      margin-right: $u-3;
    }
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
  }
  .add-button {
    font-size: 3rem;
  }
  .band-member {
    border-bottom: $border-light-gray;
    display: flex;
    display: -webkit-flex;
    justify-content: space-between;
    -webkit-justify-content: space-between;
  }
}

.modal-new-member {
  width: 650px;
  input {
    background: white;
    &:focus {
      border: $border-light-gray;
    }
  }
  .new-member-wrapper {
    display: flex;
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      border: 1px solid transparent;
      transition: padding-left 0.1s ease-in-out;
      &:hover {
        background: $ultra-light-gray;
        border: $border-very-light-gray;
      }
    }
    .search-wrapper {
      padding-top: $u-2;
      width: 100%;
      padding-right: $u-2;
      .input-wrapper {
        padding-bottom: $u-2;
        border-bottom: $border-very-light-gray;
        input {
          width: 100%;
        }
      }
      li {
        font-size: 0.9rem;
        font-weight: 600;
        padding: $u-1 $u-1 $u-1 0;
        &:hover {
          padding-left: $u-1;
        }
      }
    }
  }
}