@import '../../../../sass/variables';

.navbar {
  display: flex;
  flex-direction: column;
  margin-top: $u-5;
  @media #{$is-sm} {
    margin-top: $u-6;
    a {
      font-size: 1.7rem;
      padding: $u-5 !important;
      img {
        height: 30px !important;
      }
    }
  }
  a {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: $u-3;
    height: 2.5rem;
    opacity: .85;
    color:white;
    transition: all 0.2s ease-in-out;
    padding: $u-05;
    padding-left: $u-1;
    width: 100%;
    &:hover {
      opacity: 1;
      border-radius: $border-radius-light;
      img {
        opacity: 1;
      }
    }
    &.activeLink {
      opacity: 1;
      color: white;
      font-weight: 600;
      img {
        opacity: 1;
      }
    }
  }
  // &.active {
  //     padding-left: calc(#{$u-3});

  // }
  img {
    opacity: .8;
    margin-right: $u-3;
    height: 100%;
    width: auto;
  }
}