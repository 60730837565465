@import '../../../sass/variables';

.home {
  &.wait-confirmation {
    .spinner-1 {
      width: 150px;
      height: 150px;
    }
    .spinner-1::after {
      width: 150px;
      height: 150px;
      border-color: lightgray transparent lightgray transparent;;
    }
  }
}

.welcome-view {
  justify-content: center;
  -webkit-justify-content: center;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  align-items: center;
  h1 {
    color: $main_color;
  }
  img {
    height: 200px;
    @media #{$is-sm} {
      height: 150px;
    }
  }
}

.waiting-confirmation-panel {
  position: relative;
  top: 15vh;
  justify-content: center;
  -webkit-justify-content: center;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  -webkit-align-items: center;
  align-items: center;
  height: 100%;
  text-align: center;
  padding: $u-5 $u-5;

  h2 {
    justify-content: center;
    -webkit-justify-content: center;
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    -webkit-flex-direction: row;
    align-items: center;
    text-align: center;
  }
  img {
    width: 7rem;
    max-height: 7rem;
  }
  @media #{$is-sm} {
    border: none;
    padding: $u-2 $u-2;
  }

}